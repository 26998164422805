/* eslint-disable no-tabs */
(function (window, document) {
  let windowWidth = 0; // calculated width of the window
  let mouseDown = false; // boolean to check whether mouse button is pressed
  let mouseXOffset = 0; // offset of the mouse since it has been pressed

  const scrollerObjs = []; // carousel scroller objects
  class CarouselScroller {
    // Carousel scroller object, constructor params:
    // 1. dom element
    // 2. amount of displayed slides for small screen
    // 3. max pixels for above amount of slides
    // 4. amount of slides for medium screen
    // 5. max pixels for above
    // 6. amount of slides for big screen (above medium max pixels)
    // 7. Total x margin of each slide
    // 8. The offset to leave when the container is smaller than the sw variable
    constructor(scroller, s, sw, m, mw, l, xMargin, smallSizeOffset = 25) {
      this.scroller = scroller;
      this.slides = scroller.querySelectorAll('.carousel-scroller__slide');
      this.buttonP = scroller.querySelector('.carousel-scroller-arrow__wrapper--left');
      this.buttonN = scroller.querySelector('.carousel-scroller-arrow__wrapper--right');
      this.track = scroller.querySelector('.carousel-scroller__track');
      this.list = scroller.querySelector('.carousel-scroller__list');
      this.imgs = scroller.querySelectorAll('.carousel-scroller__img');

      this.slidesAmount = this.slides.length;
      this.scrollable = false;
      this.rawIndex = 0; // index of current slide
      this.lastRawIndex = 0;
      this.index = 0; // index of current slide
      this.maxIndex = this.slidesAmount - 1; // max possible index in current view (slide display amount)
      this.locked = false; // locked if user swipes through slides
      this.x0 = null; // x offset for swiping through slides
      this.swiping = false; // boolean for checking whether user is swiping or clicking link

      this.s = s;
      this.sw = sw;
      this.m = m;
      this.mw = mw;
      this.l = l;
      this.slideWidth = 0; // calculated width of a slide
      this.slideXMargin = xMargin;
      this.smallSizeOffset = smallSizeOffset;
      this.slideWidthMargin = 0; // calculated width of a slide with margin
      this.currentDisplay = 0; // amount of slides that can be seen in current view

      // prevent dom image/element dragging to allow for swiping

      this.scroller
        .querySelector('.carousel-scroller-arrow__wrapper--left')
        .classList.add('hide--visibility');
      this.scroller
        .querySelector('.carousel-scroller-arrow__wrapper--right')
        .classList.add('hide--visibility');

      for (let i = 0; i < this.slides; i++) {
        this.slides[i].ondragstart = function () {
          return false;
        };
        if (this.imgs && this.imgs.length > 0) {
          this.imgs[i].ondragstart = function () {
            return false;
          };
        }
      }
      // reset slide and track width
      this.resetTrack();
    }

    // get mouse offset, remove transition effects and lock onto slider when mouse down
    lock(e, self) {
      self.x0 = unify(e).clientX;
      this.track.style.transition = '';
      this.locked = true;
    }

    // recenter carousel scroller on the current slide calculated based on mouse offset after dragging
    recenter(e, self) {
      // if locked (user was swiping)
      if (self.locked) {
        const dx = unify(e).clientX - self.x0; // get mouse delta x
        let s = 0;
        if (dx !== 0) {
          s = dx > 0 ? 1 : -1; // get mouse direction
        }
        let f = +((s * dx) / windowWidth).toFixed(2); // f friction for calculating transition animation speed based on how far user has swiped

        // if mouse movement was shorther than slide width
        if (Math.abs(dx) < self.slideWidth) {
          s = Math.round(dx / (self.slideWidth - self.slideWidth / 2)); // reduce scroll threshold for easier swiping on mobile
        } else {
          s = Math.round(dx / self.slideWidth); // calculate amount of swiped through slides
        }

        /* if (s < 0 && self.rawIndex <= self.maxIndex) {
          self.index -= s;
          self.boundsCheck(false); // check whether user swiped beyond track boudns
          self.rawIndex = self.index;
        } else if (s > 0) {
          self.index -= s;
          self.boundsCheck(false); // check whether user swiped beyond track boudns
          self.rawIndex = self.index;
        } */

        // this /should/ fix the issue of tapping thumbnails in mobile not scrolling correctly
        // but we'll only know in prod; old logic commented out above - HL 2023-02-10
        if (self.swiping) {
          self.index -= s;
          self.boundsCheck(false); // check whether user swiped beyond track boudns
          self.rawIndex = self.index;
        }

        f = 1 - f; // get correct friction

        self.translate(f);
        self.x0 = null;
      }
    }

    // function continously activated during swiping action
    drag(e, self) {
      if (self.x0 || (self.x0 === 0 && mouseDown)) {
        mouseXOffset = Math.round(unify(e).clientX - self.x0); // get offset

        this.swiping = !!(mouseXOffset < -30 || mouseXOffset > 30); // check whether user is swiping (offset beyond threshold)
        if (this.imgs && this.imgs.length > 0) {
          if (mouseXOffset > 0) {
            const dIndex = Math.floor(mouseXOffset / this.slideWidth);
            const newIndex = this.index + dIndex;
            if (newIndex > 0) {
              this.slides[newIndex].classList.remove('hide--visibility');
              this.imgs[newIndex].classList.remove('hide__default');
            }
          } else {
            const dIndex = Math.ceil(mouseXOffset / this.slideWidth);
            const newIndex = this.index - dIndex + this.currentDisplay;
            if (newIndex < this.slidesAmount) {
              this.slides[newIndex].classList.remove('hide--visibility');
              this.imgs[newIndex].classList.remove('hide__default');
            }
          }
        }

        const newPos = (this.index / this.slidesAmount) * -this.track.offsetWidth + mouseXOffset; // calculate new position of track during swipe
        this.track.style.transform = `translate(${newPos}px)`;
      }
    }

    displayHiddenSlides() {
      if (this.imgs && this.imgs.length > 0) {
        for (let i = this.index; i < this.index + this.currentDisplay; i++) {
          if (i < this.slidesAmount) {
            this.slides[i].classList.remove('hide--visibility');
            this.imgs[i].classList.remove('hide__default');
          } else {
            const j = this.slidesAmount - i + this.slidesAmount;
            this.slides[j].classList.remove('hide--visibility');
            this.imgs[j].classList.remove('hide__default');
          }
        }
      }
    }

    rawBoundsCheck(loop) {
      if (this.rawIndex >= this.slidesAmount) {
        this.rawIndex = loop ? 0 : this.slidesAmount - 1;
      } else if (this.rawIndex < 0) {
        this.rawIndex = loop ? this.slidesAmount - 1 : 0;
      }

      this.index = this.rawIndex;
      if (this.rawIndex > this.maxIndex) {
        // this.index = (loop) ? this.maxIndex : this.index;
        this.index = this.maxIndex;
      }

      this.displayHiddenSlides();
    }

    // loop param if true go back to slide at other end if false stop at current end
    boundsCheck(loop) {
      if (this.index > this.maxIndex) {
        this.index = loop ? 0 : this.maxIndex;
      }
      if (this.index < 0) {
        this.index = loop ? this.maxIndex : 0;
      }

      this.displayHiddenSlides();
    }

    // translate with transition effect after user completes swiping
    translate(f) {
      // eslint-disable-next-line no-unused-vars
      // const width = this.scroller.offsetWidth;
      // let newPos = this.index * -this.slideWidthMargin - this.slideXMargin / 2;
      const slideWidth = document.querySelector('.carousel-scroller__img')?.width;
      let newPos = this.index * -(slideWidth + this.slideXMargin);
      if (this.slides) {
        newPos =
          this.index * -this.slideWidthMargin - 22.5 - this.slideXMargin / 2 + this.smallSizeOffset;
      }
      // if (width < this.sw && this.slides.length > 0) {
      //   // newPos = this.index * -this.slideWidthMargin - 22.5 - this.slideXMargin / 2 + this.smallSizeOffset;
      // }

      this.track.style.transform = `translate(${newPos}px)`;
      const transitionSpeed = f * 0.5;
      this.track.style.transition = `transform ${transitionSpeed}s ease-out`;
      this.locked = false;
    }

    // reset track size based on screen/window size
    resetTrack() {
      size();
      const width = this.scroller.offsetWidth;
      // if screen width is below small view, display amount of slides for small view
      if (width < this.sw) {
        this.currentDisplay = this.s < this.slidesAmount ? this.s : this.slidesAmount;
        this.slideWidth = this.s > 1 ? width / this.s - this.slideXMargin : width - 10;
      } else if (width < this.mw) {
        // if it's below medium view display amount for medium view
        this.currentDisplay = this.m < this.slidesAmount ? this.m : this.slidesAmount;
        this.slideWidth = width / this.m + 1;
        this.slideWidth -= this.slideXMargin;
      } else {
        // otherwise display amount for large view
        this.currentDisplay = this.l < this.slidesAmount ? this.l : this.slidesAmount;
        this.slideWidth = width / this.l + 1;
        this.slideWidth -= this.slideXMargin;
      }

      // if
      const wasScrollable = this.scrollable;
      this.scrollable = this.currentDisplay < this.slidesAmount;

      if (wasScrollable !== this.scrollable) {
        this.scroller
          .querySelector('.carousel-scroller-arrow__wrapper--left')
          .classList.toggle('hide--visibility');
        this.scroller
          .querySelector('.carousel-scroller-arrow__wrapper--right')
          .classList.toggle('hide--visibility');
      }

      // margin of a slide with margin included
      this.slideWidthMargin = this.slideWidth + this.slideXMargin;

      // set maximum index to amount of slides minus displayed slides
      this.maxIndex = this.slidesAmount - this.currentDisplay;

      // if index is above max index after resizing set it to max and reposition
      this.rawBoundsCheck();

      // setting track width based on updated slides width
      const slideWidth =
        document.querySelector('.carousel-scroller__img')?.width + this.slideXMargin;
      // checks if this.slides and sets the track width based on the size of the slides/carousel
      if (this.slides) {
        this.track.style.width = `${Math.ceil(this.slideWidthMargin * this.slidesAmount)}px`;
      }
      this.track.style.width = `${Math.ceil(slideWidth * this.slidesAmount)}px`;

      // this.translate(0);
      // set width of all slides based on the screen/window width
      for (let i = 0; i < this.slides.length; i++) {
        this.slides[i].style.width = `${this.slideWidth}px`;
        if (this.imgs && this.imgs.length > 0) {
          if (i >= this.index && i < this.currentDisplay) {
            this.slides[i].classList.remove('hide--visibility');
            this.imgs[i].classList.remove('hide__default');
          }
        }
      }
    }
  }

  function addEvents(currentSlider) {
    currentSlider.buttonP.addEventListener('click', () => {
      currentSlider.index--;
      currentSlider.boundsCheck(true);
      currentSlider.rawIndex = currentSlider.index;
      currentSlider.translate(1);
    });

    currentSlider.buttonN.addEventListener('click', () => {
      currentSlider.index++;
      currentSlider.boundsCheck(true);
      currentSlider.rawIndex = currentSlider.index;
      currentSlider.translate(1);
    });

    currentSlider.list.addEventListener('click', (e) => {
      if (currentSlider.swiping) {
        e.preventDefault();
        e.stopPropagation();
      }
    });

    currentSlider.list.addEventListener(
      'mousemove',
      (e) => {
        currentSlider.drag(e, currentSlider);
      },
      false,
    );
    currentSlider.list.addEventListener(
      'touchmove',
      (e) => {
        currentSlider.drag(e, currentSlider);
      },
      false,
    );

    currentSlider.list.addEventListener(
      'mousedown',
      (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (currentSlider.scrollable) {
          currentSlider.swiping = false;
          mouseDown = true;
          currentSlider.lock(e, currentSlider);
        }
      },
      false,
    );
    currentSlider.list.addEventListener(
      'touchstart',
      (e) => {
        mouseDown = true;
        if (currentSlider.scrollable) {
          currentSlider.lock(e, currentSlider);
        }
      },
      false,
    );

    document.addEventListener(
      'mouseup',
      (e) => {
        currentSlider.recenter(e, currentSlider);
        mouseDown = false;
      },
      false,
    );
    document.addEventListener(
      'touchend',
      (e) => {
        currentSlider.recenter(e, currentSlider);
        mouseDown = false;
      },
      false,
    );

    window.addEventListener(
      'resize',
      () => {
        currentSlider.resetTrack();
      },
      false,
    );

    currentSlider.list.addEventListener('click', () => {
      if (currentSlider.swiping && currentSlider.buttonP.classList.contains('hide__default')) {
        currentSlider.buttonP.classList.remove('hide__default');
      }
    });
  }

  function unify(e) {
    return e.changedTouches ? e.changedTouches[0] : e;
  }

  function size() {
    windowWidth = window.innerWidth;
  }

  /**
   * Initialize a carousel object
   * @param {object} options The carousel options to initialize
   * @param {Element} options.container The carousel container
   * @param {number} [options.smallSize = 0] The slides size when the container is smaller than smallMaxWidth
   * @param {number} [options.smallMaxWidth = 200] The max width to apply the slides size based on smallSize param
   * @param {number} [options.mediumSize = 0] The slides size when the container is smaller than mediumMaxWidth
   * @param {number} [options.mediumMaxWidth = 200] The max width to apply the slides size based on mediumSize param
   * @param {number} [options.largeSize = 5] The slides size when the container is larger than mediumMaxWidth param
   * @param {number} [options.slideSidesMargin = 5] The total sides margin of a slide. If a slide has a margin of 5px this should be 5+5 = 10
   * @param {number} [options.smallSizeOffset = 25] The offset to leave when the container is smaller than the smallMaxWidth param
   * @param {boolean} [events = true] If addEvents should be called
   */
  function initCarouselScroller(options, events = true) {
    const {
      container,
      smallSize = 0,
      smallMaxWidth = 200,
      mediumSize = 0,
      mediumMaxWidth = 200,
      largeSize = 5,
      slideSidesMargin = 20,
      smallSizeOffset = 25,
    } = options;

    if (!container) return undefined;

    const scroller = new CarouselScroller(
      container,
      smallSize,
      smallMaxWidth,
      mediumSize,
      mediumMaxWidth,
      largeSize,
      slideSidesMargin,
      smallSizeOffset,
    );
    scrollerObjs.push(scroller);
    size();
    if (events) addEvents(scroller);

    container.classList.remove('hide--visibility');

    return scroller;
  }

  // create objects for carousel and scrollers object (e.g. vehicle display carousel)
  function initVDCS(carouselWithScroller) {
    const carouselScroller = carouselWithScroller.querySelector('.carousel-scroller--vdp');
    return initCarouselScroller(
      {
        container: carouselScroller,
        smallSize: 3,
        smallMaxWidth: 400,
        mediumSize: 3,
        mediumMaxWidth: 1065,
        largeSize: 8,
      },
      false,
    );
  }

  // create objects for presonyze carousel scrollers
  function carouselScrollerInit() {
    const carouselScrollers = document.querySelectorAll('.carousel-scroller--personyze');
    for (let i = 0; i < carouselScrollers.length; i++) {
      initCarouselScroller({
        container: carouselScrollers[i],
        smallSize: 1,
        smallMaxWidth: 580,
        mediumSize: 3,
        mediumMaxWidth: 1065,
        largeSize: 4,
        slideSidesMargin: 16,
      });
    }
  }

  // To init block scroller for Ad Injection in model hub
  function initBlockScroller(blockScroller) {
    // Get the scroller if it has the 3 col class
    const blockScroller3Col = blockScroller.classList.contains('.block-nav-scroller--3-col');
    // If 3 col class is present then set teh config below to it's specific values
    initCarouselScroller({
      container: blockScroller,
      smallSize: 1,
      smallMaxWidth: blockScroller3Col ? 580 : 849,
      mediumSize: blockScroller3Col ? 2 : 1,
      mediumMaxWidth: 849,
      largeSize: blockScroller3Col ? 3 : 2,
      slideSidesMargin: 5,
    });
  }

  vm.onload(() => {
    // create objects for car carousel scrollers
    const carouselScrollers = document.querySelectorAll('.carousel-scroller--cars');
    for (let i = 0; i < carouselScrollers.length; i++) {
      initCarouselScroller({
        container: carouselScrollers[i],
        smallSize: 1,
        smallMaxWidth: 580,
        mediumSize: 3,
        mediumMaxWidth: 1065,
        largeSize: 4,
        slideSidesMargin: 16,
      });
    }

    const carouselTrimScrollers = document.querySelectorAll('.js-carousel-scroller--trim');
    for (let i = 0; i < carouselTrimScrollers.length; i++) {
      initCarouselScroller({
        container: carouselTrimScrollers[i],
        smallSize: 1,
        smallMaxWidth: 580,
        mediumSize: 3,
        mediumMaxWidth: 1065,
        largeSize: 3,
        slideSidesMargin: 16,
      });
    }

    if (window.innerWidth > 579 && window.innerWidth < 641) {
      const specialOfferTiles = document.querySelectorAll('.js-carousel-scroller-margin');
      for (let i = 0; i < specialOfferTiles.length; i++) {
        specialOfferTiles[i].style.marginLeft = '6px';
      }
    }

    // create objects for news carousel scrollers
    const newsScrollers = document.querySelectorAll('.carousel-scroller--news');
    for (let i = 0; i < newsScrollers.length; i++) {
      initCarouselScroller({
        container: newsScrollers[i],
        smallSize: 1,
        smallMaxWidth: 580,
        mediumSize: 2,
        mediumMaxWidth: 1065,
        largeSize: 3,
        slideSidesMargin: 16,
      });
    }
    handleCarouselArrows();
    // create objects for news carousel scrollers
    const blockScrollers = document.querySelectorAll('.block-nav-scroller');
    for (let i = 0; i < blockScrollers.length; i++) {
      initBlockScroller(blockScrollers[i]);
    }
  });

  // Displays left arrow only once right arrow has been clicked for Special Offers
  const leftArrow = document.querySelectorAll('.js-special-offer-left-arrow');
  const rightArrow = document.querySelectorAll('.js-special-offer-right-arrow');

  for (let i = 0; i < leftArrow.length; i++) {
    if (rightArrow[i]) {
      rightArrow[i].addEventListener(
        'click',
        () => {
          leftArrow[i].classList.remove('hide__default');
        },
        { once: true },
      );
    }
  }

  // Listen to the activeTab custom event when a tab is changed to reset the sliders
  window.addEventListener('activeTab', (event) => {
    if (event.detail.target) {
      const scroller = event.detail.target.querySelector('.js-carousel-scroller');
      if (scroller) {
        const carouselScroller = scrollerObjs.filter((scr) => scr.scroller === scroller);
        if (carouselScroller.length) {
          carouselScroller[0].resetTrack();
        }
      }
    }
  });

  const handleCarouselArrows = () => {
    const carouselContainer = document.querySelectorAll('.carousel__container');
    if (!carouselContainer) return;
    carouselContainer.forEach((container) => {
      const carouselSlides = container.querySelectorAll('.carousel-scroller__slide');
      const carouselSlideDisplay =
        carouselSlides && carouselSlides.length > 1 ? 'inline-block' : 'none';
      const carouselLeftArrow = container.querySelector('.carousel-arrow__wrapper--left');
      const carouselRightArrow = container.querySelector('.carousel-arrow__wrapper--right');
      const leftArrowGallery = container.querySelector('.gallery__control--prev ');
      const rightArrowGallery = container.querySelector('.gallery__control--next');
      if (carouselLeftArrow) carouselLeftArrow.style.display = carouselSlideDisplay;
      if (carouselRightArrow) carouselRightArrow.style.display = carouselSlideDisplay;
      if (leftArrowGallery) leftArrowGallery.style.display = carouselSlideDisplay;
      if (rightArrowGallery) rightArrowGallery.style.display = carouselSlideDisplay;
    });
  };
  window.carouselScrollerObjs = scrollerObjs;
  window.initVDCS = initVDCS;
  window.carouselScrollerInit = carouselScrollerInit;
  window.initBlockScroller = initBlockScroller;
  window.initCarouselScroller = initCarouselScroller;
})(window, document);
