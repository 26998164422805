/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
(function newCarousel(window, document) {
  const galleryObjs = [];
  class Carousel {
    constructor(carousel, containsDots = true, containsArrows = true, interval = 0) {
      // initialise all member variables
      this.containsDots = containsDots;
      this.containsArrows = containsArrows;
      this.carousel = carousel;
      this.carouselSlidesContainersBool = false;
      this.carouselSlidesContainers = carousel.querySelectorAll('.carousel__slides-container');
      if (this.carouselSlidesContainers.length > 0) {
        this.carouselSlidesContainersBool = true;
        this.currentSlidePosition = 0;
        this.slides = this.carouselSlidesContainers[0].querySelectorAll('.carousel__slide');
        this.images = this.carouselSlidesContainers[0].querySelectorAll('.carousel__image');
      } else {
        this.slides = this.carousel.querySelectorAll('.carousel__slide');
        this.images = this.carousel.querySelectorAll('.carousel__image');
      }
      this.length = this.slides.length;
      this.imagesLength = this.images.length;
      this.dotsWrapper = this.carousel.querySelector('.carousel__dots-wrapper');
      this.dots = [];
      this.imageSrcs = [];
      this.currIndex = 0;
      this.expectedIndex = 0;
      this.lastIndex = 0;
      this.automaticSlider = 0;
      this.automaticTimeInterval = interval;
      this.swiping = false;
      this.loading = false;
      this.transitionAmount = 0;

      // if there is no slides, hide closest parent section
      if (!this.length || this.length === 0) {
        this.carousel.closest('section').style.display = 'none';
      } else {
        // If carousel slides container is not found
        if (this.carouselSlidesContainersBool === false) {
          this.slides[0].classList.add('carousel__slide--current');
          this.slides[0].classList.remove('hide--collapse');
        }
        // make sure to disable html dragging of the slides to allow for swiping
        this.carousel.ondragstart = () => false;

        for (let i = 0; i < this.imagesLength; i += 1) {
          this.slides[i].ondragstart = () => false;

          this.images[i].ondragstart = () => false;

          this.images[i].setAttribute('data-banner-position', i);
        }

        // If there's more than one slide to show in the carousel
        if (this.length > 1) {
          if (containsArrows) {
            // Show default left and right carousel arrows
            const defaultLeftArrow = this.carousel.querySelector('.carousel__arrow--left');
            const defaultRightArrow = this.carousel.querySelector('.carousel__arrow--right');
            if (defaultLeftArrow) {
              defaultLeftArrow.style.display = 'block';
            }
            if (defaultRightArrow) {
              defaultRightArrow.style.display = 'block';
            }

            // Show circular carousel arrows
            const circularArrowsWrapper = this.carousel.querySelector(
              '.carousel-circular-arrow__wrapper',
            );
            if (circularArrowsWrapper) {
              circularArrowsWrapper.style.display = 'flex';
            }
          }
          this.pushImageSrcs();
          if (containsDots && this.dotsWrapper) {
            this.buildDots();
            this.setDot();
          } else if (this.dotsWrapper) {
            this.buildDots();
            this.setScrollingDots();
          }
        }
        // initialise first image

        LL.preloadChildElements(this.slides[this.currIndex]);
        if (interval !== 0) {
          this.runInterval(1);
        }
      }
    }

    runInterval(nextSlide) {
      const self = this;
      let newIndex = this.getIndex(nextSlide);
      this.preloadSlide(newIndex);
      this.automaticSlider = setInterval(() => {
        newIndex = self.getIndex(self.currIndex + 1);
        self.scrollHandler(newIndex);
        newIndex = self.getIndex(newIndex + 1);
        self.preloadSlide(newIndex);
      }, self.automaticTimeInterval);
    }

    pushImageSrcs() {
      for (let i = 0; i < this.length; i += 1) {
        this.imageSrcs.push('');
      }
    }

    /**
     * Handle the active states for the dots
     */
    handleDotClasses() {
      if (this.dots && this.dots.length) {
        this.dots[this.lastIndex].classList.remove('active');
        this.dots[this.currIndex].classList.add('active');
      }
    }

    buildDots() {
      const dotsFragment = document.createDocumentFragment();
      // Create each navigation dot within the dot wrapper
      for (let i = 0; i < this.length; i += 1) {
        const dot = document.createElement('li');
        dot.setAttribute('data-slide', i);
        dot.classList.add('carousel__dot');
        dotsFragment.appendChild(dot);
        this.dots.push(dot);
      }
      this.dotsWrapper.appendChild(dotsFragment);
    }

    setDot() {
      if (this.containsDots || this.dotsWrapper) {
        // Set last dot as inactive and current as active
        this.handleDotClasses();
      }
    }

    /**
     * Set the scrolling dots attributes
     */
    setScrollingDots() {
      if (this.dots.length > 0) {
        this.dots.forEach((dot) => {
          dot.style.transform = 'scale(0.666667)';
        });

        const proxy = new Proxy(this.dots, {
          get(target, prop) {
            if (!Number.isNaN(prop)) {
              const index = (parseInt(prop, 10) + target.length) % target.length;
              return target[index];
            }
            return target[prop];
          },
        });

        if (this.dotsWrapper && this.currIndex > 2 && this.currIndex < this.length - 2) {
          this.dotsWrapper.style.transform = `translateX(-${(this.transitionAmount += 11)}px)`;
        }

        if (this.dotsWrapper) {
          this.handleDotClasses();
        }

        if (this.length > 3) {
          if (this.currIndex > 1 && proxy[this.lastIndex - 1]) {
            proxy[this.lastIndex - 1].style.transform = 'scale(0.83333)';
          }

          this.dots[this.lastIndex].style.transform = 'scale(1)';
          this.dots[this.currIndex].style.transform = 'scale(1)';

          this.dots[this.currIndex + 1]
            ? (this.dots[this.currIndex + 1].style.transform = 'scale(1)')
            : null;

          this.dots[this.currIndex + 2]
            ? (this.dots[this.currIndex + 2].style.transform = 'scale(0.83333)')
            : null;

          if (this.currIndex === 0 && this.lastIndex === 0) {
            this.dots[this.currIndex + 2]
              ? (this.dots[this.currIndex + 2].style.transform = 'scale(1)')
              : null;

            this.dots[this.currIndex + 3]
              ? (this.dots[this.currIndex + 3].style.transform = 'scale(0.83333)')
              : null;
          }

          if (this.currIndex === this.length - 1) {
            this.dots[this.currIndex - 2]
              ? (this.dots[this.currIndex - 2].style.transform = 'scale(1)')
              : null;

            this.dots[this.currIndex - 3]
              ? (this.dots[this.currIndex - 3].style.transform = 'scale(0.83333)')
              : null;
          }

          if (this.currIndex === 0 && this.lastIndex === this.length - 1) {
            this.dots[this.currIndex + 2]
              ? (this.dots[this.currIndex + 2].style.transform = 'scale(1)')
              : null;

            this.dots[this.currIndex + 3]
              ? (this.dots[this.currIndex + 3].style.transform = 'scale(0.83333)')
              : null;

            this.length < 7 && this.dots[this.currIndex + 4]
              ? (this.dots[this.currIndex + 4].style.transform = 'scale(0.666667)')
              : null;
          }
        } else {
          this.dots[this.lastIndex].style.transform = 'scale(0.83333)';
          this.dots[this.currIndex].style.transform = 'scale(1)';

          this.dots[this.currIndex + 1]
            ? (this.dots[this.currIndex + 1].style.transform = 'scale(0.83333)')
            : null;

          this.dots[this.currIndex + 2]
            ? (this.dots[this.currIndex + 2].style.transform = 'scale(0.633337)')
            : null;
        }
      }
    }

    /**
     * Set the scrolling decrements dots
     */
    setScrollingDotsDecrement() {
      if (this.dots.length > 0) {
        this.dots.forEach((dot) => {
          dot.style.transform = 'scale(0.666667)';
        });

        /**
         * New proxy so we can target minus values in the dots array
         */
        const proxy = new Proxy(this.dots, {
          get(target, prop) {
            // eslint-disable-next-line no-restricted-globals
            if (!isNaN(prop)) {
              prop = parseInt(prop, 10);
              if (prop < 0) {
                prop += target.length;
              }
            }
            return target[prop];
          },
        });

        if (this.dotsWrapper && this.currIndex < this.length - 3) {
          this.dotsWrapper.style.transform = `translateX(-${(this.transitionAmount -= 11)}px)`;
        }

        this.handleDotClasses();

        if (this.length > 3) {
          proxy[this.lastIndex - 2]
            ? (proxy[this.lastIndex - 2].style.transform = 'scale(0.83333)')
            : null;

          proxy[this.lastIndex - 1]
            ? (proxy[this.lastIndex - 1].style.transform = 'scale(0.83333)')
            : null;
          this.dots[this.lastIndex].style.transform = 'scale(1)';
          this.dots[this.currIndex].style.transform = 'scale(1)';

          this.dots[this.currIndex - 1]
            ? (this.dots[this.currIndex - 1].style.transform = 'scale(1)')
            : null;

          this.dots[this.currIndex - 2]
            ? (this.dots[this.currIndex - 2].style.transform = 'scale(0.83333)')
            : null;

          this.dots[this.currIndex + 2]
            ? (this.dots[this.currIndex + 2].style.transform = 'scale(0.83333)')
            : null;

          if (this.currIndex === 0 && this.length < 7) {
            this.dots[this.currIndex + 2]
              ? (this.dots[this.currIndex + 2].style.transform = 'scale(1)')
              : null;

            this.dots[this.currIndex + 3]
              ? (this.dots[this.currIndex + 3].style.transform = 'scale(0.83333)')
              : null;
          }

          // If index is 0 set the 3rd dot to scale 1
          if (this.currIndex === 0 && this.length >= 7) {
            this.dots[this.currIndex + 2]
              ? (this.dots[this.currIndex + 2].style.transform = 'scale(1)')
              : null;

            this.dots[this.currIndex + 3]
              ? (this.dots[this.currIndex + 3].style.transform = 'scale(0.83333)')
              : null;
          }

          if (this.lastIndex === 0 || this.currIndex === this.length - 1) {
            this.dots[this.currIndex - 2]
              ? (this.dots[this.currIndex - 2].style.transform = 'scale(1)')
              : null;

            this.dots[this.currIndex - 3]
              ? (this.dots[this.currIndex - 3].style.transform = 'scale(0.83333)')
              : null;

            if (this.length === 5) {
              this.dots[this.currIndex - 4]
                ? (this.dots[this.currIndex - 4].style.transform = 'scale(0.633337)')
                : null;
            }
          }

          if (this.length === 5 && this.currIndex === 0 && this.lastIndex === 1) {
            this.dots[this.currIndex + 4]
              ? (this.dots[this.currIndex + 4].style.transform = 'scale(0.633337)')
              : null;
          }
        } else {
          this.dots[this.currIndex].style.transform = 'scale(1)';

          this.dots[this.currIndex + 1]
            ? (this.dots[this.currIndex + 1].style.transform = 'scale(0.83333)')
            : null;

          this.dots[this.currIndex - 1]
            ? (this.dots[this.currIndex - 1].style.transform = 'scale(0.83333)')
            : null;
        }
      }
    }

    preloadSlide(index) {
      const self = this;
      if (!this.imageSrcs[index] || this.imageSrcs[index] === '') {
        this.imageSrcs[index] = 'loading';
        LL.preloadChildElements(this.slides[this.getIndex(index)]).then(() => {
          self.imageSrcs[index] = 'loaded';
          if (self.expectedIndex !== self.currIndex && self.expectedIndex === index) {
            self.scrollHandler(self.getIndex(index));
          }
        });
      }
    }

    /**
     * Append the current scroll count
     * @returns
     */
    appendCurrentCount() {
      const countEl = this.carousel.querySelector('.js-gallery-counter');
      if (!countEl) return;

      countEl.innerHTML = `${this.currIndex + 1} of ${this.length + 1}`;
    }

    // update index and display current slide
    scroll(index) {
      // Remove loading overlay
      this.carousel
        .querySelector('.carousel__overlay')
        .classList.remove('carousel__overlay--visible');
      this.loading = false;

      // Set current index
      this.currIndex = index;

      // If carousel slide container exists
      if (this.carouselSlidesContainersBool) {
        // If current index is greater than last index
        if (this.currIndex > this.lastIndex) {
          // If going from first index to last
          if (this.lastIndex === 0 && this.currIndex === this.length - 1) {
            this.currentSlidePosition = this.length * 100 - 100;
          } else {
            this.currentSlidePosition = this.currIndex * 100;
          }
          if (this.dotsWrapper) {
            this.setScrollingDots();
          }
          // Else if current index is less than last index
        } else if (this.currIndex < this.lastIndex) {
          if (this.lastIndex === this.length - 1 && this.currIndex === 0) {
            this.currentSlidePosition = 0;
          } else {
            this.currentSlidePosition = this.currIndex * 100;
          }

          if (this.dotsWrapper) {
            this.setScrollingDotsDecrement();
          }
        }

        let transitionSpeed = 0.5;
        // If going from last to first or first to last image
        if (
          // eslint-disable-next-line operator-linebreak
          (this.lastIndex === this.length - 1 && this.currIndex === 0) ||
          (this.lastIndex === 0 && this.currIndex === this.length - 1)
        ) {
          transitionSpeed = 0;
        }

        /**
         * If going from last to first, reset the transition amount
         * If going from first to last, calculate the transition amount
         */
        if (this.lastIndex === this.length - 1 && this.currIndex === 0) {
          if (this.dotsWrapper) {
            this.transitionAmount = 0;
            this.dotsWrapper.style.transform = `translateX(-${this.transitionAmount}px)`;
            this.setScrollingDots();
          }
        } else if (this.lastIndex === 0 && this.currIndex === this.length - 1) {
          if (this.dotsWrapper) {
            this.transitionAmount = (this.length - 5) * 11;
            this.dotsWrapper.style.transform = `translateX(-${this.transitionAmount}px)`;
            this.setScrollingDotsDecrement();
          }
        }

        // Scroll carousel to width of current slide
        for (let i = 0; i < this.carouselSlidesContainers.length; i += 1) {
          const slideContainerWidth = this.carouselSlidesContainers[i];
          slideContainerWidth.style.transform = `translate(-${this.currentSlidePosition}%)`;

          // Set carousel speed
          const slideContainerSpeed = this.carouselSlidesContainers[i];
          slideContainerSpeed.style.transition = `transform ${transitionSpeed}s ease-out`;
        }

        // If carousel slide container does not exist
      } else {
        this.slides[index].classList.remove('hide--collapse');
        this.slides[this.lastIndex].classList.remove('carousel__slide--current');
        this.slides[this.currIndex].classList.add('carousel__slide--current');
      }

      // Set gallery dot
      this.setDot();

      // Set last index as current index
      this.lastIndex = this.currIndex;

      this.appendCurrentCount();
    }

    swipeHandler(touchStartCoord, touchEndCoord) {
      // Handles swipes based on values provided by event handlers
      const sumOfTouch = [0, 0];
      // get X offset
      sumOfTouch[0] = touchStartCoord[0] - touchEndCoord[0];
      // get Y offset
      sumOfTouch[1] = touchStartCoord[1] - touchEndCoord[1];
      return new Promise((resolve) => {
        // if X offset > Y offset
        if (Math.abs(sumOfTouch[0]) > Math.abs(sumOfTouch[1])) {
          if (sumOfTouch[0] > 30) {
            // if swiped further than 30px left, scroll right
            this.swiping = true;
            this.setExpectedIndex(this.getIndex(this.currIndex + 1));
            this.scrollHandler(this.getIndex(this.currIndex + 1));
          } else if (sumOfTouch[0] < -30) {
            // if swiped further than 30px left, scroll left
            this.swiping = true;
            this.setExpectedIndex(this.getIndex(this.currIndex - 1));
            this.scrollHandler(this.getIndex(this.currIndex - 1));
          } else {
            this.swiping = false; // false if pressed not swiped
          }
        } else {
          this.swiping = false;
        }
        resolve(this.swiping);
      });
    }

    scrollHandler(index) {
      return new Promise((resolve) => {
        // if the image is loaded, set it as current
        if (this.imageSrcs[index] === 'loaded' || this.imagesLength === 0 || index === 0) {
          resolve(this.scroll(index));
        } else if (this.imageSrcs[index] !== 'loading') {
          // Wait for image to load before displaying and apply loading overlay
          this.imageSrcs[index] = 'loading';
          this.carousel
            .querySelector('.carousel__overlay')
            .classList.add('carousel__overlay--visible');
          const clearOverlay = setTimeout(() => {
            this.carousel
              .querySelector('.carousel__overlay')
              .classList.remove('carousel__overlay--visible');
            resolve();
          }, 3000);

          LL.preloadChildElements(this.slides[index]).then(() => {
            this.imageSrcs[index] = 'loaded';
            if (index === this.expectedIndex) {
              // if current index matches expected index then update displayed slide.
              this.carousel
                .querySelector('.carousel__overlay')
                .classList.remove('carousel__overlay--visible');
              clearTimeout(clearOverlay);
              resolve(this.scroll(index));
            }
          });
        } else {
          this.carousel
            .querySelector('.carousel__overlay')
            .classList.add('carousel__overlay--visible');
          setTimeout(() => {
            this.carousel
              .querySelector('.carousel__overlay')
              .classList.remove('carousel__overlay--visible');
            resolve();
          }, 3000);
        }
      });
    }

    updateCarouselSlideExternal(index) {
      const newIndex = this.getIndex(index); // get slide index from the data-slide attribute
      // preloadSlide(newIndex);
      this.setExpectedIndex(newIndex);
      this.scrollHandler(newIndex);
    }

    // Essential for smooth lazy loading during rapid scrolling, ensuring correct index display.
    setExpectedIndex(index) {
      this.expectedIndex = index;
    }

    getIndex(index) {
      return ((index % this.length) + this.length) % this.length;
    }

    getIndexExternal() {
      return this.currIndex;
    }
  }

  function addEvents(currentcarousel) {
    const onTouchStartCord = [0, 0];
    const onTouchEndCord = [0, 0];

    if (currentcarousel.automaticTimeInterval !== 0) {
      currentcarousel.carousel.addEventListener(
        'mouseover',
        () => {
          clearInterval(currentcarousel.automaticSlider);
        },
        false,
      );

      currentcarousel.carousel.addEventListener('mouseleave', () => {
        currentcarousel.runInterval(currentcarousel.currIndex + 1);
      });
    }

    if (currentcarousel.containsArrows) {
      // Default carousel arrows
      const defaultLeftArrow = currentcarousel.carousel.querySelector(
        '.carousel-arrow__wrapper--left',
      );
      const defaultRightArrow = currentcarousel.carousel.querySelector(
        '.carousel-arrow__wrapper--right',
      );

      // Provides left and right arrow logic for any styled carousel arrows with these class names
      const leftArrow = currentcarousel.carousel.querySelector('.js-carousel-arrow--left');
      const rightArrow = currentcarousel.carousel.querySelector('.js-carousel-arrow--right');

      // eslint-disable-next-line func-style
      const getNextSlideIndex = (direction) => {
        switch (direction) {
          case 'left':
            return currentcarousel.getIndex(currentcarousel.currIndex - 1);
          case 'right':
            return currentcarousel.getIndex(currentcarousel.currIndex + 1);
          default:
            // eslint-disable-next-line no-console
            return console.error('direction is defined incorrectly');
        }
      };

      // Add event listeners to arrows to move to the next/previous slide
      // eslint-disable-next-line func-style
      const arrowScrollHandler = (arrowElem, direction) => {
        if (arrowElem) {
          arrowElem.addEventListener('mouseenter', () => {
            currentcarousel.preloadSlide(getNextSlideIndex(direction));
          });

          arrowElem.addEventListener('click', () => {
            currentcarousel.setExpectedIndex(getNextSlideIndex(direction));
            currentcarousel.scrollHandler(getNextSlideIndex(direction));
            currentcarousel.preloadSlide(getNextSlideIndex(direction));
          });
        }
      };

      try {
        arrowScrollHandler(defaultLeftArrow, 'left');
        arrowScrollHandler(defaultRightArrow, 'right');
        arrowScrollHandler(leftArrow, 'left');
        arrowScrollHandler(rightArrow, 'right');
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error sorting the carousel arrows', error);
      }
    }

    // on a navigation dot hover...
    if (currentcarousel.containsDots && currentcarousel.dotsWrapper) {
      currentcarousel.dotsWrapper.addEventListener('mouseover', (e) => {
        if (e.target && e.target.nodeName === 'LI') {
          const newIndex = parseInt(e.target.getAttribute('data-slide'), 10);
          currentcarousel.preloadSlide(newIndex);
        }
      });

      // on a navigation dot click...
      currentcarousel.dotsWrapper.addEventListener('click', (e) => {
        if (e.target && e.target.nodeName === 'LI') {
          const newIndex = parseInt(e.target.getAttribute('data-slide'), 10);
          currentcarousel.setExpectedIndex(currentcarousel.getIndex(newIndex));
          currentcarousel.scrollHandler(newIndex);
        }
      });
    }

    if (currentcarousel.carouselSlidesContainersBool) {
      for (let i = 0; i < currentcarousel.carouselSlidesContainers.length; i += 1) {
        // Swipe images by comparing touch/mouse positions at start and end
        currentcarousel.carouselSlidesContainers[i].addEventListener('touchstart', (e) => {
          onTouchStartCord[0] = e.touches[0].clientX;
          onTouchStartCord[1] = e.touches[0].clientY;
          if (e.target.nodeName !== 'IMG') return;
          const newIndex = currentcarousel.getIndex(currentcarousel.currIndex + 1);
          currentcarousel.preloadSlide(newIndex);
          const newIndex2 = currentcarousel.getIndex(currentcarousel.currIndex - 1);
          currentcarousel.preloadSlide(newIndex2);
        });

        currentcarousel.carouselSlidesContainers[i].addEventListener('touchend', (e) => {
          onTouchEndCord[0] = e.changedTouches[0].clientX;
          onTouchEndCord[1] = e.changedTouches[0].clientY;
          currentcarousel.swipeHandler(onTouchStartCord, onTouchEndCord).then((res) => {
            currentcarousel.swiping = res;
          });
        });

        currentcarousel.carouselSlidesContainers[i].addEventListener('click', (e) => {
          if (currentcarousel.swiping) {
            e.preventDefault();
            e.stopPropagation();
          }
        });

        currentcarousel.carouselSlidesContainers[i].addEventListener('mousedown', (e) => {
          onTouchStartCord[0] = e.clientX;
          onTouchStartCord[1] = e.clientY;
          // currentcarousel.swiping = true;
          if (e.target.nodeName !== 'IMG') return;
          const newIndex = currentcarousel.getIndex(currentcarousel.currIndex + 1);
          currentcarousel.preloadSlide(newIndex);
          const newIndex2 = currentcarousel.getIndex(currentcarousel.currIndex - 1);
          currentcarousel.preloadSlide(newIndex2);
        });

        currentcarousel.carouselSlidesContainers[i].addEventListener('mouseup', (e) => {
          onTouchEndCord[0] = e.clientX;
          onTouchEndCord[1] = e.clientY;

          currentcarousel.swipeHandler(onTouchStartCord, onTouchEndCord).then((res) => {
            currentcarousel.swiping = res;
          });
        });
      }
    } else {
      // Swipe images by comparing touchstart/mousedown position with touchend/mouseup position.
      currentcarousel.carousel.addEventListener('touchstart', (e) => {
        onTouchStartCord[0] = e.touches[0].clientX;
        onTouchStartCord[1] = e.touches[0].clientY;
        if (e.target.nodeName !== 'IMG') return;
        const newIndex = currentcarousel.getIndex(currentcarousel.currIndex + 1);
        currentcarousel.preloadSlide(newIndex);
        const newIndex2 = currentcarousel.getIndex(currentcarousel.currIndex - 1);
        currentcarousel.preloadSlide(newIndex2);
      });

      currentcarousel.carousel.addEventListener('touchend', (e) => {
        onTouchEndCord[0] = e.changedTouches[0].clientX;
        onTouchEndCord[1] = e.changedTouches[0].clientY;
        currentcarousel.swipeHandler(onTouchStartCord, onTouchEndCord).then((res) => {
          currentcarousel.swiping = res;
        });
      });

      currentcarousel.carousel.addEventListener('click', (e) => {
        if (currentcarousel.swiping) {
          e.preventDefault();
          e.stopPropagation();
        }
      });

      currentcarousel.carousel.addEventListener('mousedown', (e) => {
        onTouchStartCord[0] = e.clientX;
        onTouchStartCord[1] = e.clientY;
        // currentcarousel.swiping = true;
        if (e.target.nodeName !== 'IMG') return;
        const newIndex = currentcarousel.getIndex(currentcarousel.currIndex + 1);
        currentcarousel.preloadSlide(newIndex);
        const newIndex2 = currentcarousel.getIndex(currentcarousel.currIndex - 1);
        currentcarousel.preloadSlide(newIndex2);
      });

      currentcarousel.carousel.addEventListener('mouseup', (e) => {
        onTouchEndCord[0] = e.clientX;
        onTouchEndCord[1] = e.clientY;

        currentcarousel.swipeHandler(onTouchStartCord, onTouchEndCord).then((res) => {
          currentcarousel.swiping = res;
        });
      });
    }
  }

  function pausePlayer() {
    const x = document.getElementById('cdvideo');
    x.src += 'pause';
    const y = x.src;
    const q = y.replace('pause', '');
    x.src = q;
  }

  // Video billboard(deprecating fancy box)
  setTimeout(() => {
    const billboard = [].slice.call(document.getElementsByClassName('carousel__slide'));
    for (let i = 0; i < billboard.length; i += 1) {
      if (billboard[i].children[0].host === 'www.youtube.com') {
        document.getElementById('cdvideo').src = billboard[i].children[0].href;
        billboard[i].children[0].classList.add('modal__trigger');
        billboard[i].children[0].setAttribute('data-modal', 'modal-billboard');
        ModalEffects.addModalTrigger(billboard[i].children[0], pausePlayer);
        billboard[i].children[0].removeAttribute('href');
        billboard[i].children[0].removeAttribute('target');
      }
    }
  }, 300);

  // initialising carousel and scroller object (e.g. VDP gallery)
  function initVDC(carouselWithScroller) {
    const gallery = carouselWithScroller.querySelector('.carousel--vdp');
    return new Carousel(gallery, false);
  }

  function initVDCB() {
    const gallery = document.querySelector('.carousel--vdp');
    return new Carousel(gallery, false);
  }

  // Loading all billboards and adding events

  vm.onload(() => {
    let index = 0;

    const galleries = document.querySelectorAll('.carousel--billboard');
    for (let i = 0; i < galleries.length; i += 1) {
      galleryObjs.push(new Carousel(galleries[i]));
      if (galleryObjs[index].length > 1) {
        addEvents(galleryObjs[index]);
      }
      index += 1;
    }

    const splitGalleries = document.querySelectorAll('.carousel--billboard-no-dots');
    let totalSlides;
    let currentSlide;

    // eslint-disable-next-line func-style
    const updatePagination = () => {
      const container = document.getElementById('pagination-container');
      container.textContent = `${currentSlide} / ${totalSlides}`;
    };

    // eslint-disable-next-line func-style
    const handleArrowClick = (direction) => {
      switch (direction) {
        case 'left':
          currentSlide = currentSlide > 1 ? currentSlide - 1 : totalSlides;
          break;

        case 'right':
          currentSlide = currentSlide < totalSlides ? currentSlide + 1 : 1;
          break;
        default:
          currentSlide = 1;
      }
      updatePagination();
    };

    for (let i = 0; i < splitGalleries.length; i += 1) {
      galleryObjs.push(new Carousel(splitGalleries[i], false));

      if (galleryObjs[index].length > 1) {
        addEvents(galleryObjs[index]);

        totalSlides = galleryObjs[index].imagesLength;
        currentSlide = 1;

        updatePagination();

        document.querySelector('.js-carousel-arrow--left').addEventListener('click', () => {
          handleArrowClick('left');
        });

        document.querySelector('.js-carousel-arrow--right').addEventListener('click', () => {
          handleArrowClick('right');
        });
      }

      index += 1;
    }

    const automaticGalleries = document.querySelectorAll('.carousel--automatic');
    for (let i = 0; i < automaticGalleries.length; i += 1) {
      galleryObjs.push(new Carousel(automaticGalleries[i], true, true, 4000));
      if (galleryObjs[index].length > 1) {
        addEvents(galleryObjs[index]);
      }
      index += 1;
    }

    const vdps = document.querySelectorAll('.carousel--vdp-no-thumbnails');
    for (let i = 0; i < vdps.length; i += 1) {
      initModal(vdps[i], index);
      galleryObjs.push(new Carousel(vdps[i], false));
      if (galleryObjs[index].length > 1) {
        addEvents(galleryObjs[index]);
      }
      index += 1;
    }

    const testimonials = document.querySelectorAll('.customer-testimonial.js-carousel');
    for (let i = 0; i < testimonials.length; i += 1) {
      galleryObjs.push(new Carousel(testimonials[i]));
      if (galleryObjs[index].length > 1) {
        addEvents(galleryObjs[index]);
      }
      index += 1;
    }
  });

  const myWindow = window;
  myWindow.carouselImageObjs = galleryObjs;
  myWindow.initVDC = initVDC;
  myWindow.initVDCB = initVDCB;
  // eslint-disable-next-line prettier/prettier
}(window, document));
