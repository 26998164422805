/**
 * Primary Nav Transparent JS for transparent features on nav
 */

((window, document) => {
  /**
   * Get the elements
   */
  let scrollpos = window.scrollY;
  const sitePrimaryNav = document.querySelector('.js-site-primary-nav');
  const siteNavContainer = document.querySelector('.js-site-nav-container');
  const headerHeight = sitePrimaryNav.offsetHeight;

  /**
   * Add transparent class on load
   */
  if (siteNavContainer) {
    siteNavContainer.classList.add('site-nav-container--is-transparent');
  }

  /**
   * Add and remove state classes on nav
   */
  const addScrollClass = () => sitePrimaryNav.classList.add('site-primary-nav--solid');
  const removeScrollClass = () => sitePrimaryNav.classList.remove('site-primary-nav--solid');

  window.addEventListener('scroll', () => {
    scrollpos = window.scrollY;

    /**
     * Once teh scroll position is 90px past the header height
     * add and remove/remove the class based on position
     */
    if (scrollpos >= headerHeight + 75) {
      addScrollClass();
      siteNavContainer.classList.remove('site-nav-container--is-transparent');
    } else {
      removeScrollClass();
    }

    if (scrollpos === 0) {
      siteNavContainer.classList.add('site-nav-container--is-transparent');
    }
  });
})(window, document);
